import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Sidebar from "../dashboard/components/Sidebar";
import Header from "../dashboard/components/Header";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomers } from "../../redux/Customers";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import DataTable from "datatables.net-dt";
import { getLedgerReport } from "../../redux/api/apiReports";
import { ledgerCustomerSelector, ledgerSelector } from "../../redux/Reports";
import { getAllCustomers } from "../../redux/api/apiCustomer";
export default function Ledger() {
	const [exportToPdf, setExportToPdf] = useState(false);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const customerList = useSelector(selectCustomers);
	const ledgerCustomer = useSelector(ledgerCustomerSelector);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const custmerValue = (e) => {
		if (e === null) {
			setFilterList({
				...filterList,
				cus_id: "",
			});
			return;
		}
		setFilterList({
			...filterList,
			cus_id: e.id,
		});
	};
	const [currentDate, setCurrentDate] = useState(new Date());
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate() + 3);
	// console.log(defaultDate);

	const [filterList, setFilterList] = useState({
		datefrom: "",
		dateto: "",
		cus_id: "",
	});

	const ledgerList = useSelector(ledgerSelector);

	useEffect(() => {
		dispatch(getAllCustomers());
	}, [dispatch, reset]);
	const [active, setActive] = useState(false);
	const getLedgData = () => {
		// if (!filterList.cus_id) {
		// 	Swal.fire({
		// 		icon: "error",
		// 		title: "Oops...",
		// 		text: "Please Select Customer",
		// 	});
		// 	return;
		// }
		if (!filterList.datefrom) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Select Date From",
			});
			return;
		}
		if (!filterList.dateto) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Select Date To",
			});
			return;
		}

		dispatch(
			getLedgerReport(filterList, (res) => {
				// console.log(res);
				setActive(true);
				setExportToPdf(true);
			})
		);
	};

	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const exportPDFWithComponent = async () => {
		const printArea = document.getElementById("printableArea");
		const opt = {
			margin: 0.3,
			filename: "Ledger.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2, useCORS: true },
			jsPDF: {
				unit: "in",
				format: [14, 17],
				orientation: "landscape",
			},
		};
		html2pdf().set(opt).from(printArea).save("Ledger.pdf");
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Ledger</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active"> Ledger</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="department_id">Client *</label>
													<Controller
														name="cus_id"
														control={control}
														rules={{ required: true }}
														render={({ field }) => (
															<AsyncSelect
																{...field}
																isClearable
																defaultOptions={customerList}
																loadOptions={customerLoadOptions}
																getOptionLabel={(option) => option.cus_name}
																getOptionValue={(option) => option.id}
																placeholder="Select Customer"
																value={customerList.find(
																	(obj) => obj.id === field.value
																)}
																onChange={(e) => {
																	field.onChange(e);
																	custmerValue(e);
																}}
															/>
														)}
													/>
												</div>
											</div>

											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary"
													onClick={getLedgData}
												>
													Search
												</button>
											</div>

											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success ml-2"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								id="printableArea"
								className="col-sm-12"
								style={{ display: active ? "" : "none" }}
							>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-12">
												<div className="invoice p-3 mb-3">
													<div className="row invoice-info">
														<div className="col-sm-4 invoice-col">
															<div className="company-logo">
																<img
																	src="/1689772513.jpeg"
																	alt="logo"
																	className="lg-logo"
																	style={{
																		width: "250px",
																		height: "180px",
																	}}
																/>
																{/* <address className="mt-2">
																	<strong>Phone:</strong>
																	{company_phone}
																	<br /> <strong>Email: </strong>
																	{company_email}
																	<br /> <strong>Address: </strong>
																	{company_address}
																</address> */}
															</div>
														</div>
														<div className="col-sm-6 offset-sm-2 invoice-col float-right text-md-right">
															<h5>Ledger Report</h5>
														</div>
													</div>
													<hr />
													<div className="row mt-5 position-relative">
														<div
															className="position-absolute w-100 h-100 opacity-50 z-10 tbl-loading"
															style={{ display: "none" }}
														>
															<div className="loader07" />
														</div>
														<div className="table-responsive table-custom">
															<table className="table table-sm">
																<thead>
																	<tr>
																		<th>Sl no</th>
																		<th>Customer Name</th>
																		<th> Date</th>
																		<th> Transaction Number</th>
																		<th>Type</th>
																		<th>Debit</th>
																		<th>Credit</th>
																		<th>Balance</th>
																	</tr>
																</thead>
																<tbody>
																	{ledgerList.length === 0 ? (
																		<tr>
																			<td colSpan="6">No data found</td>
																		</tr>
																	) : (
																		ledgerList.map((item, index) => (
																			<React.Fragment key={index}>
																				<tr>
																					<td>{index + 1}</td>
																					<td>
																						{item.customer_info.cus_id}
																						<br />
																						{item.customer_info.cus_name}
																					</td>
																				</tr>
																				{item.data.map((data, invoiceIndex) => (
																					<tr key={invoiceIndex}>
																						<td></td>
																						<td></td>
																						<td>{data.date}</td>
																						<td>
																							{data.invoice_no && (
																								<span>
																									Invoice No: {data.invoice_no},{" "}
																								</span>
																							)}
																							{data.receipt_no && (
																								<span>
																									Receipt No: {data.receipt_no},{" "}
																								</span>
																							)}
																							{data.notes && (
																								<span>
																									Others: {data.notes}
																								</span>
																							)}
																						</td>
																						<td>{data.type}</td>
																						<td>{data.debit}</td>
																						<td>{data.credit}</td>
																						<td>{data.balance}</td>
																					</tr>
																				))}
																			</React.Fragment>
																		))
																	)}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
