import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDuePaymentReport } from "../../redux/api/apiReports";
import { duePaymentSelector } from "../../redux/Reports";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { selectCustomers } from "../../redux/Customers";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
export default function Duepayment() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const customerList = useSelector(selectCustomers);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const custmerValue = (e) => {
		if (e === null) {
			setFilterList({
				...filterList,
				cus_id: "",
			});
			return;
		}
		setFilterList({
			...filterList,
			cus_id: e.id,
		});
	};
	const [filterList, setFilterList] = useState({
		cus_id: "",
		datefrom: "",
		dateto: "",
	});
	let table = new DataTable("#due-table");
	const dispatch = useDispatch();
	const dueList = useSelector(duePaymentSelector);
	// console.log(dueList);
	useEffect(() => {
		dispatch(getDuePaymentReport());
		dispatch(getAllCustomers());
	}, [dispatch]);

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#due-table", {
			data: dueList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.invoice_no) {
							return data.invoice_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.tax) {
							return data.tax;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.tax_amount) {
							return data.tax_amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.total) {
							return data.total;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.total_paid_amount) {
							return data.total_paid_amount;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.balance) {
							return data.balance;
						}
						return 0;
					},
				},
			],
			lengthMenu: [[-1], ["All"]],
		});
	}, [dueList]);
	const [exportToPdf, setExportToPdf] = useState(false);
	const getDueList = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getDuePaymentReport(filterList, (res) => {
				if (res) {
					Swal.close();
					// show export_to_pdf button
					setExportToPdf(true);
				}
			})
		);
	};
	const [currentDate, setCurrentDate] = useState("");
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const exportPDFWithComponent = async () => {
		try {
			const printArea = document.createElement("div");
			// Fetch the image dynamically
			// const response = await fetch(logo); // Replace with the correct URL for your dynamically generated image
			// const blob = await response.blob();
			// const imageURL = URL.createObjectURL(blob);

			// // Create a temporary image element
			// const img = new Image();
			// img.src = imageURL;
			// Add your HTML code above the table
			printArea.innerHTML = `
  

	  <div class="col-lg-12">
                                    <div class="invoice p-3 mb-3">
                                        <div class="row invoice-info">
                                            <div class="col-sm-4 invoice-col">
                                                <div class="company-logo"><img src="/1689772513.jpeg" alt="logo" style="height:200;width:250" class="lg-logo">
                                                   
                                                </div>
                                            </div>
                                        <h3 style="text-align:center">Due Payment Reports </h3>
                                        </div>
                                        <hr>
                                     

                                    </div>
                                </div>
    `;

			// Append the cloned table to the printArea
			const originalTable = document.getElementById("due-table");
			const clonedTable = originalTable.cloneNode(true);
			clonedTable.getElementsByTagName("thead")[0].style.backgroundColor =
				"blue";
			clonedTable.getElementsByTagName("thead")[0].style.color = "white";
			// Set visibility of cloned table columns to true
			const clonedTableColumns = clonedTable.getElementsByTagName("th");
			for (let i = 0; i < clonedTableColumns.length; i++) {
				clonedTableColumns[i].style.display = "table-cell";
			}
			let totalSum = 0;
			const rows = clonedTable.getElementsByTagName("tr");
			for (let i = 1; i < rows.length; i++) {
				// Start from 1 to skip the header row
				const cells = rows[i].getElementsByTagName("td");
				if (cells.length >= 0) {
					const cellValue = parseFloat(cells[8].textContent); // Assuming column 7 is 0-based
					if (!isNaN(cellValue)) {
						totalSum += cellValue;
					}
				}
			}

			// Create a new row for the total sum
			const totalRow = document.createElement("tr");
			totalRow.style.backgroundColor = "gray";
			totalRow.style.color = "white";
			for (let i = 0; i < 9; i++) {
				// Assuming there are 7 columns in your table
				const totalCell = document.createElement("td");
				if (i === 8) {
					// If it's the last column (column 7), add the total sum
					totalCell.textContent = `Total Amount: ${totalSum.toFixed(2)}`; // Assuming you want 2 decimal places
				} else {
					totalCell.textContent = ""; // Empty for other columns
				}
				totalRow.appendChild(totalCell);
			}
			clonedTable.querySelector("tbody").appendChild(totalRow);
			printArea.appendChild(clonedTable);

			const opt = {
				margin: 1,
				filename: "DuePayment.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: "in", format: [20.4, 20], orientation: "landscape" },
			};

			html2pdf().set(opt).from(printArea).save();
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};
	const exportExcelWithComponent = async () => {
		const table = document.getElementById("due-table");
		const rows = Array.from(table.getElementsByTagName("tr"));

		// Extract table headers
		const headers = Array.from(rows.shift().getElementsByTagName("th")).map(
			(header) => header.textContent
		);

		// Extract table data
		const data = rows.map((row) => {
			const cells = Array.from(row.getElementsByTagName("td")).map(
				(cell) => cell.textContent
			);
			return cells.join(",");
		});

		let totalSum = 0;
		rows.forEach((row) => {
			const cells = Array.from(row.getElementsByTagName("td"));
			if (cells.length >= 1) {
				const cellValue = parseFloat(cells[8].textContent); // Assuming column 7 is 0-based
				if (!isNaN(cellValue)) {
					totalSum += cellValue;
				}
			}
		});
		const totalRow = [
			"Total",
			"",

			"",
			"",
			"",
			"",
			"",
			"",
			`${totalSum.toFixed(2)}`,
		]; // Add the total sum as the 8th column

		// Create report header
		const reportHeader = "Report Name:Due Payment Report\n\n";

		// Create CSV content with report header
		const csvContent =
			reportHeader +
			`${headers.join(",")}\n${data.join("\n")}\n${totalRow.join(",")}`;

		// Create a download link
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = "due_payment_reports.csv";

		// Trigger the download
		downloadLink.click();

		// Cleanup
		URL.revokeObjectURL(url);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	}
	accountType = accountType.replace(/['"]+/g, "");
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Due Payment</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active">Due Payment</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										{/* <div className="row"> */}
										{accountType === "admin" || accountType === "user" ? (
											<div className="row">
												<div className="col-md-4">
													<div className="form-group">
														<label htmlFor="department_id">Client *</label>
														<Controller
															name="cus_id"
															control={control}
															rules={{ required: true }}
															render={({ field }) => (
																<AsyncSelect
																	{...field}
																	isClearable
																	defaultOptions={customerList}
																	loadOptions={customerLoadOptions}
																	getOptionLabel={(option) => option.cus_name}
																	getOptionValue={(option) => option.id}
																	placeholder="Select Customer"
																	value={customerList.find(
																		(obj) => obj.id === field.value
																	)}
																	onChange={(e) => {
																		field.onChange(e);
																		custmerValue(e);
																	}}
																/>
															)}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label htmlFor="vehicle">DateFrom</label>
														<input
															type="date"
															className="form-control"
															id="dateFrom"
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	datefrom: e.target.value,
																})
															}
															defaultValue={defaultDate}
															value={filterList.datefrom}
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<label htmlFor="vehicle">DateTo</label>
														<input
															type="date"
															className="form-control"
															id="dateTo"
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	dateto: e.target.value,
																})
															}
															defaultValue={defaultDate}
															value={filterList.dateto}
														/>
													</div>
												</div>
												<div
													className="col-md-2 mt-4"
													style={{
														display: "inline-grid",
														marginLeft: "10px",
													}}
												>
													<button
														type="button"
														className="btn btn-primary"
														onClick={getDueList}
													>
														Search
													</button>
												</div>
												<div
													className="col-md-2 mt-4"
													style={{
														display: exportToPdf ? "inline-grid" : "none",
														marginLeft: "10px",
													}}
												>
													<button
														type="button"
														className="btn btn-success ml-2"
														id="export_to_pdf"
														onClick={exportPDFWithComponent}
													>
														PDF
													</button>
												</div>
												<div
													className="col-md-2 mt-4"
													style={{
														display: exportToPdf ? "inline-grid" : "none",
														marginLeft: "10px",
													}}
												>
													<button
														type="button"
														className="btn btn-info ml-2"
														id="export_to_pdf"
														onClick={exportExcelWithComponent}
													>
														Excel
													</button>
												</div>
											</div>
										) : (
											<div className="col-md-12 row">
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="vehicle">DateFrom</label>
														<input
															type="date"
															className="form-control"
															id="dateFrom"
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	datefrom: e.target.value,
																})
															}
															defaultValue={defaultDate}
															value={filterList.datefrom}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="vehicle">DateTo</label>
														<input
															type="date"
															className="form-control"
															id="dateTo"
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	dateto: e.target.value,
																})
															}
															defaultValue={defaultDate}
															value={filterList.dateto}
														/>
													</div>
												</div>

												<div
													className="col-md-4 mt-4"
													style={{
														display: "inline-grid",
														// marginLeft: "10px",
													}}
												>
													<button
														type="button"
														className="btn btn-primary"
														onClick={getDueList}
													>
														Search
													</button>
												</div>
												<div className="col-md-4 mt-4">
													<button
														type="button"
														className="btn btn-success"
														id="export_to_pdf"
														onClick={exportPDFWithComponent}
														style={{
															width: "250px",
														}}
													>
														PDF
													</button>
												</div>
												<div
													className="col-md-4 mt-4"
													style={{
														marginLeft: "-127px",
													}}
												>
													<button
														type="button"
														className="btn btn-info "
														id="export_to_pdf"
														onClick={exportExcelWithComponent}
														style={{
															width: "250px",
														}}
													>
														Excel
													</button>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="due-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Invoice Number</th>
														<th>Customer </th>
														<th>Quantity</th>
														<th>Vat % </th>
														<th>Vat Amount</th>
														<th>Total</th>
														<th>Paid Amount</th>
														<th>Due Amount</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
