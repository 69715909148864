import React, { useEffect } from "react";
import { getPaymentInfoById } from "../../redux/api/apiTaxinvoice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTaxInvoiceByResult } from "../../redux/TaxInvoice";
import "./test.css";
import html2pdf from "html2pdf.js";
export default function Downloadtax() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const taxinvoice = useSelector((state) => state.taxinvoice.byresult);
	useEffect(() => {
		if (id) {
			dispatch(getPaymentInfoById(id ? id : 0));
		}
	}, [dispatch, id]);

	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const export_pdf = () => {
		const element = document.getElementById("reciept");
		const opt = {
			margin: 1,
			filename: "Reciept.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: "in", format: [15, 12], orientation: "landscape" },
		};

		//  i want to download first page only
		html2pdf().set(opt).from(element).save();
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="container">
			<div
				style={{
					padding: 10,
				}}
			>
				<button className="btn btn-primary" onClick={export_pdf}>
					Download PDF
				</button>
				<Link
					className="btn btn-primary"
					to="/tax/payments"
					style={{
						marginLeft: 10,
					}}
				>
					BACK
				</Link>
			</div>
			<div className="reciept" id="reciept">
				<div className="row" style={{ marginTop: 10 }}>
					<div
						className="col-md-12"
						style={{
							textAlign: "center",
						}}
					>
						<h4>Payment Voucher</h4>
					</div>
					<div className="col-md-8">
						<div
							className="col-md-3"
							style={{
								padding: 10,
							}}
						>
							<img
								className="media-object img-thumbnail user-img"
								style={{ height: 80 }}
								alt="logo"
								src="/1689772513.jpeg"
							/>
						</div>

						<div
							className="col-md-9 text-right"
							style={{
								padding: 10,
							}}
						>
							<h4 className="heading">{company_name}</h4>
							<h5 className="heading">{company_address}</h5>
							<h6 className="heading">{company_phone}</h6>
							<h6 className="heading">{company_email}</h6>
						</div>
					</div>

					<div className="col-md-4">
						<div className="form-group">
							<label className="col-md-4 control">Date :</label>
							<div className="col-md-8">
								<input
									id
									name="name"
									type="text"
									placeholder={
										taxinvoice.data ? taxinvoice.data.payment_date : "-"
									}
									className="form-control"
									disabled
								/>
							</div>
						</div>
						<div className="form-group">
							<label className="col-md-4 control">Invoice No. :</label>
							<div className="col-md-8">
								<input
									id
									name="name"
									type="text"
									placeholder={
										taxinvoice.data ? taxinvoice.data.invoice.invoice_no : "-"
									}
									className="form-control"
									disabled
								/>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div
						className="col-md-12 row"
						style={{
							padding: 25,
						}}
					>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Payment From :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.data ? taxinvoice.data.customer.cus_name : "-"}
							</label>
						</div>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Payment Method :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.data ? taxinvoice.data.payment_method : "-"}
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div
						className="col-md-12 row"
						style={{
							padding: 25,
						}}
					>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Cheque No. :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.data ? taxinvoice.data.cheque_no : "-"}
							</label>
						</div>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Reciept No. :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.data ? taxinvoice.data.receipt_no : "-"}
							</label>
						</div>
					</div>
				</div>

				<div className="row">
					<div
						className="col-md-12 row"
						style={{
							padding: 25,
						}}
					>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Recieved By :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.data ? taxinvoice.data.created_who.name : "-"}
							</label>
						</div>
						<div className="col-md-6">
							<label className="col-md-3 control" htmlFor>
								Amount In Words :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.number_in_word ? taxinvoice.number_in_word : "-"}
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div
						className="col-md-12 row"
						style={{
							padding: 25,
						}}
					>
						<div className="col-md-12">
							<label className="col-md-3 control" htmlFor>
								Notes :
							</label>
							<label className="col-md-9 control" htmlFor>
								{taxinvoice.notes ? taxinvoice.notes : "-"}
							</label>
						</div>
					</div>
				</div>

				<div className="row" style={{ marginTop: 80 }}>
					<div className="col-md-9"></div>
					<div className="col-md-3">
						<div className="form-group">
							<label className="control" htmlFor="name">
								<u>Authorised Signatury</u>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
