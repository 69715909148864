import React, { useRef } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import html2pdf from "html2pdf.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { selectCustomers } from "../../redux/Customers";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";

import { getTransactionsReport } from "../../redux/api/apiReports";
import { transactionsSelector } from "../../redux/Reports";
import { DownloadTableExcel } from "react-export-table-to-excel";
export default function TransactionList() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const saleList = useSelector(transactionsSelector);

	const customerList = useSelector(selectCustomers);
	useEffect(() => {
		dispatch(
			getTransactionsReport(filterList, (res) => {
				if (res.status === 200) {
					Swal.close();
					// show export_to_pdf button
					setExportToPdf(true);
				}
			})
		);
		dispatch(getAllCustomers());
	}, [dispatch]);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const custmerValue = (e) => {
		if (e === null) {
			setFilterList({
				...filterList,
				cus_id: "",
			});
			return;
		}
		setFilterList({
			...filterList,
			cus_id: e.id,
		});
	};
	let table = new DataTable("#sale-table");

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#sale-table", {
			data: saleList.data,

			columns: [
				//  auto increment id column
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.transaction_begining_time) {
							return data.transaction_begining_time;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_plate_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle_code) {
							return data.vehicle_code;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.payment_type) {
							return data.payment_type;
						}
						return "";
					},
				},
			],
			lengthMenu: [[-1], ["All"]],
		});
	}, [saleList.data]);

	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}

	const exportPDFWithComponent = async () => {
		try {
			const printArea = document.createElement("div");

			printArea.innerHTML = `
  

	  <div class="col-lg-12">
                                    <div class="invoice p-3 mb-3">
                                        <div class="row invoice-info">
                                            <div class="col-sm-4 invoice-col">
                                                <div class="company-logo"><img src="/1689772513.jpeg" alt="logo" style="height:200;width:250" class="lg-logo">
                                                   
                                                </div>
                                            </div>
											<h3 style="text-align:center">Transaction Reports </h3>
                                        
                                        </div>
                                        <hr>
                                     

                                    </div>
                                </div>
    `;

			// Append the cloned table to the printArea
			const originalTable = document.getElementById("sale-table");
			const clonedTable = originalTable.cloneNode(true);
			// how to append color in table head to cloned table
			clonedTable.getElementsByTagName("thead")[0].style.backgroundColor =
				"blue";
			clonedTable.getElementsByTagName("thead")[0].style.color = "white";

			// Set visibility of cloned table columns to true
			const clonedTableColumns = clonedTable.getElementsByTagName("th");
			for (let i = 0; i < clonedTableColumns.length; i++) {
				clonedTableColumns[i].style.display = "table-cell";
			}

			let totalSum = 0;
			const rows = clonedTable.getElementsByTagName("tr");
			for (let i = 1; i < rows.length; i++) {
				// Start from 1 to skip the header row
				const cells = rows[i].getElementsByTagName("td");
				if (cells.length >= 9) {
					const cellValue = parseFloat(cells[8].textContent); // Assuming column 7 is 0-based
					if (!isNaN(cellValue)) {
						totalSum += cellValue;
					}
				}
			}

			// Create a new row for the total sum
			const totalRow = document.createElement("tr");
			totalRow.style.backgroundColor = "gray";
			totalRow.style.color = "white";
			for (let i = 0; i < 9; i++) {
				// Assuming there are 7 columns in your table
				const totalCell = document.createElement("td");
				if (i === 8) {
					// If it's the last column (column 7), add the total sum
					totalCell.textContent = `Total Amount: ${totalSum.toFixed(2)}`; // Assuming you want 2 decimal places
				} else {
					totalCell.textContent = ""; // Empty for other columns
				}
				totalRow.appendChild(totalCell);
			}

			// Add the total row to the table body
			clonedTable.querySelector("tbody").appendChild(totalRow);

			printArea.appendChild(clonedTable);

			const opt = {
				margin: 1,
				filename: "Transaction.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: "in", format: [28.4, 25], orientation: "landscape" },
			};

			html2pdf().set(opt).from(printArea).save();
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const exportExcelWithComponent = async () => {
		const table = document.getElementById("sale-table");

		// Check if the table element exists
		if (!table) {
			console.error("Table not found. Please check the element ID.");
			return;
		}

		const rows = Array.from(table.getElementsByTagName("tr"));

		// Check if there are any rows in the table
		if (rows.length === 0) {
			console.warn("No rows found in the table.");
			return;
		}

		// Extract table headers
		const headers = Array.from(rows[0].getElementsByTagName("th")).map(
			(header) => header.textContent
		);

		// Extract table data
		const data = rows.map((row) => {
			const cells = Array.from(row.getElementsByTagName("td")).map((cell) => {
				// Escape special characters and wrap in double-quotes
				return `"${cell.textContent.replace(/"/g, '""')}"`;
			});
			return cells.join(",");
		});

		// Check if the headers and data are present
		if (headers.length === 0 || data.length === 0) {
			console.warn("No headers or data found in the table.");
			return;
		}

		let totalSum = 0;
		rows.forEach((row) => {
			const cells = Array.from(row.getElementsByTagName("td"));
			if (cells.length >= 1) {
				const cellValue = parseFloat(cells[8].textContent); // Assuming column 7 is 0-based
				if (!isNaN(cellValue)) {
					totalSum += cellValue;
				}
			}
		});
		const totalRow = [
			"Total",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			`${totalSum.toFixed(2)}`,
		]; // Add the total sum as the 8th column

		// Create report header
		const reportHeader =
			"Report Name: Sales Report\nReport Date: [Insert Date Here]\n\n";

		// Create CSV content with report header and headers
		const csvContent =
			reportHeader +
			`${headers.join(",")}\n${data.join("\n")}\n${totalRow.join(",")}`;

		// Create a download link
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
		const url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = "sale.csv";

		// Trigger the download
		downloadLink.click();

		// Cleanup
		URL.revokeObjectURL(url);
	};

	const tableRef = useRef(null);

	const getSaleList = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getTransactionsReport(filterList, (res) => {
				if (res.status === 200) {
					Swal.close();
					// show export_to_pdf button
					setExportToPdf(true);
				}
			})
		);
	};
	const [exportToPdf, setExportToPdf] = useState(false);

	// current date
	const [currentDate, setCurrentDate] = useState("");
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	const [filterList, setFilterList] = useState({
		datefrom: defaultDate,
		dateto: defaultDate,
		cus_id: "",
	});
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	}
	accountType = accountType.replace(/['"]+/g, "");

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />

				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Transaction Reports</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item"> Reports</li>
										<li className="breadcrumb-item active">Transaction</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										{accountType === "admin" || accountType === "user" ? (
											<div className="row">
												<div className="col-md-12 row">
													<div className="col-md-4">
														<div className="form-group">
															<label htmlFor="department_id">Client *</label>
															<Controller
																name="cus_id"
																control={control}
																rules={{ required: true }}
																render={({ field }) => (
																	<AsyncSelect
																		{...field}
																		isClearable
																		defaultOptions={customerList}
																		loadOptions={customerLoadOptions}
																		getOptionLabel={(option) => option.cus_name}
																		getOptionValue={(option) => option.id}
																		placeholder="Select Customer"
																		value={customerList.find(
																			(obj) => obj.id === field.value
																		)}
																		onChange={(e) => {
																			field.onChange(e);
																			custmerValue(e);
																		}}
																	/>
																)}
															/>
														</div>
													</div>
													<div className="col-md-2">
														<div className="form-group">
															<label htmlFor="vehicle">DateFrom</label>
															<input
																type="date"
																className="form-control"
																id="dateFrom"
																onChange={(e) =>
																	setFilterList({
																		...filterList,
																		datefrom: e.target.value,
																	})
																}
																defaultValue={defaultDate}
																value={filterList.datefrom}
															/>
														</div>
													</div>
													<div className="col-md-2">
														<div className="form-group">
															<label htmlFor="vehicle">DateTo</label>
															<input
																type="date"
																className="form-control"
																id="dateTo"
																onChange={(e) =>
																	setFilterList({
																		...filterList,
																		dateto: e.target.value,
																	})
																}
																defaultValue={defaultDate}
																value={filterList.dateto}
															/>
														</div>
													</div>
													<div className="col-md-3 ">
														<label className>Select Type*</label>
														<select
															className="form-control"
															name="item_type"
															id="item_type"
															{...register("item_type", {})}
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	item_type: e.target.value,
																})
															}
														>
															<option value="">Select</option>
															<option value="cash">Cash</option>
															<option value="credit">Credit</option>
														</select>
													</div>
												</div>

												<div className="col-md-12 row">
													<div
														className="col-md-2 mt-4"
														style={{
															display: "inline-grid",
															marginLeft: "10px",
															height: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-primary"
															onClick={getSaleList}
														>
															Search
														</button>
													</div>
													<div
														className="col-md-2 mt-4"
														style={{
															display: exportToPdf ? "inline-grid" : "none",
															marginLeft: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-success ml-2"
															id="export_to_pdf"
															onClick={exportPDFWithComponent}
														>
															PDF
														</button>
													</div>
													<div
														className="col-md-2 mt-4"
														style={{
															display: exportToPdf ? "inline-grid" : "none",
															marginLeft: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-info ml-2"
															id="export_to_pdf"
															onClick={exportExcelWithComponent}
														>
															Excel
														</button>
													</div>
												</div>
											</div>
										) : (
											<div className="row">
												<div className="col-md-12 row">
													<div className="col-md-4">
														<div className="form-group">
															<label htmlFor="vehicle">DateFrom</label>
															<input
																type="date"
																className="form-control"
																id="dateFrom"
																onChange={(e) =>
																	setFilterList({
																		...filterList,
																		datefrom: e.target.value,
																	})
																}
																defaultValue={defaultDate}
																value={filterList.datefrom}
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-group">
															<label htmlFor="vehicle">DateTo</label>
															<input
																type="date"
																className="form-control"
																id="dateTo"
																onChange={(e) =>
																	setFilterList({
																		...filterList,
																		dateto: e.target.value,
																	})
																}
																defaultValue={defaultDate}
																value={filterList.dateto}
															/>
														</div>
													</div>
													<div className="col-md-4 ">
														<label className>Select Type*</label>
														<select
															className="form-control"
															name="item_type"
															id="item_type"
															{...register("item_type", {})}
															onChange={(e) =>
																setFilterList({
																	...filterList,
																	item_type: e.target.value,
																})
															}
														>
															<option value="">Select</option>
															<option value="cash">Cash</option>
															<option value="credit">Credit</option>
														</select>
													</div>
												</div>

												<div className="col-md-12 row">
													<div
														className="col-md-2 mt-4"
														style={{
															display: "inline-grid",
															marginLeft: "10px",
															height: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-primary"
															onClick={getSaleList}
														>
															Search
														</button>
													</div>
													<div
														className="col-md-2 mt-4"
														style={{
															display: exportToPdf ? "inline-grid" : "none",
															marginLeft: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-success ml-2"
															id="export_to_pdf"
															onClick={exportPDFWithComponent}
														>
															PDF
														</button>
													</div>
													<div
														className="col-md-2 mt-4"
														style={{
															display: exportToPdf ? "inline-grid" : "none",
															marginLeft: "10px",
														}}
													>
														<button
															type="button"
															className="btn btn-info ml-2"
															id="export_to_pdf"
															onClick={exportExcelWithComponent}
														>
															Excel
														</button>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="sale-table">
												<thead>
													<tr>
														<th>Sl no</th>

														<th>Quantity</th>
														<th> Date</th>
														<th>Customer Name</th>
														<th>Vehicle Name</th>
														<th>Vehicle Number</th>
														<th>Vehicle Plate Number</th>
														<th>Vehicle Code</th>

														<th>Amount</th>
														<th>Transaction Type</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
