import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthUser from "../../auth/AuthUser";
import { useEffect } from "react";
import Dashboard from "../../dashboard/svg/Dashboard";
import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { lastTransactionSelector } from "../../../redux/Sale";
import Modal from "react-modal";
import {
	getLastTransactions,
	getSaleById,
	updateCancelTransaction,
	updateTransactionType,
} from "../../../redux/api/apiSale";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Sidebar from "./Sidebar";
import { checkAccountType } from "../../../redux/api/apiUser";
export default function Header() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const lastTransaction = useSelector(lastTransactionSelector);

	const [isSideMoving, setSideMoving] = useState(false);
	const [isUserActive, setUserActive] = useState(false);
	const navigate = useNavigate();
	//  token check
	const { getToken } = AuthUser();

	useEffect(() => {
		if (!getToken()) {
			navigate("/login");
		}
	}, [getToken, navigate]);

	const { bellIcon } = Dashboard();
	const { users } = Dashboard();
	const { logout } = Dashboard();
	const { settings } = Dashboard();
	const { account } = Dashboard();
	// const {loggedOut} = AuthUser();

	const loggedOut = () => {
		localStorage.clear();
		navigate("/");
	};
	const changeSideBar = () => {
		// console.log("changeSideBar");
		setSideMoving(!isSideMoving);
		sessionStorage.setItem("isSideMoving", !isSideMoving);
	};
	const changeUserActive = () => {
		setUserActive(!isUserActive);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	useEffect(() => {
		const formData = new FormData();
		formData.append("accountType", accountType);
		dispatch(
			checkAccountType(formData, (res) => {
				if (res.status === 0) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: res.message,
					});

					loggedOut();
				}
			})
		);
	}, [accountType, dispatch, loggedOut]);

	return (
		<>
			<div className={isSideMoving ? "page-header " : "page-header close_icon"}>
				<div className="header-wrapper row m-0">
					<div className="header-logo-wrapper col-auto p-0">
						<div className="toggle-sidebar" onClick={changeSideBar}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								className="feather feather-grid status_toggle middle sidebar-toggle"
							>
								<rect x="3" y="3" width="7" height="7"></rect>
								<rect x="14" y="3" width="7" height="7"></rect>
								<rect x="14" y="14" width="7" height="7"></rect>
								<rect x="3" y="14" width="7" height="7"></rect>
							</svg>
						</div>

						<div className="logo-header-main">
							<Link to="/dashboard" onClick={changeSideBar}>
								<img
									className="img-fluid_changes for-light img-100"
									src="/1689772513.jpeg"
									alt=""
								/>
								<img
									className="img-fluid_changes for-dark"
									src="/1689772513.jpeg"
									alt=""
								/>
							</Link>
						</div>
					</div>
					<div className="left-header col horizontal-wrapper ps-0">
						<div className="left-menu-header">
							<ul className="app-list"></ul>
							<ul className="header-left">
								<li className="onhover-dropdown">
									<span className="f-w-600">Module</span>
									<span>
										<i className="middle" data-feather="chevron-down" />
									</span>
									<ul className="onhover-show-div left-dropdown">
										<li>
											<Link to="/manage-sale">Sale</Link>
										</li>
										<li>
											<Link to="/purchase">Purchase</Link>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<div className="nav-right col-6 pull-right right-header p-0">
						<ul className="nav-menus">
							<li>
								<Notification />
							</li>

							<li
								className="profile-nav onhover-dropdown"
								onClick={changeUserActive}
							>
								<div className="account-user">{users}</div>

								<ul
									className={
										isUserActive
											? "profile-dropdown onhover-show-div"
											: "profile-dropdown onhover-show-div active"
									}
								>
									<li>
										<Link to="/account/info">
											{account}

											<span>Account</span>
										</Link>
									</li>
									{accountType === "customer" ? (
										""
									) : (
										<li>
											<Link to="/info">
												{settings}
												<span>Settings</span>
											</Link>
										</li>
									)}
									<li>
										<Link to="#" onClick={loggedOut}>
											{logout}
											<span>Log Out</span>
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
