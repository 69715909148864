import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import DataTable from "datatables.net-dt";

import { getPurchasePaymentReport } from "../../redux/api/apiReports";
import { purchasePaymentSelector } from "../../redux/Reports";
import { getSupplierList } from "../../redux/api/apiSupplier";
import { supplierSelector } from "../../redux/Supplier";
export default function PurchasePayment() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [currentDate, setCurrentDate] = useState(new Date());
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate() + 3);
	// console.log(defaultDate);

	const [filterList, setFilterList] = useState({
		datefrom: "",
		dateto: "",
		sp_id: "",
	});
	let table = new DataTable("#p_payment-table");

	const dispatch = useDispatch();
	const paymentList = useSelector(purchasePaymentSelector);
	const supplierList = useSelector(supplierSelector);
	useEffect(() => {
		dispatch(getPurchasePaymentReport());
		dispatch(getSupplierList());
	}, [dispatch]);

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#p_payment-table", {
			data: paymentList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.supplier) {
							return data.supplier.sp_name;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.payment_date) {
							return data.payment_date;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.receipt_no) {
							return data.receipt_no;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.cheque_no) {
							return data.cheque_no;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account) {
							return data.account.account_name;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.bill_data) {
							return data.bill_data.net_total;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.notes) {
							return data.notes;
						}
						return "-";
					},
				},
			],
		});
	}, [paymentList]);
	const [exportToPdf, setExportToPdf] = useState(false);
	const getPaymentList = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getPurchasePaymentReport(filterList, (res) => {
				if (res) {
					Swal.close();
					setExportToPdf(true);
				}
			})
		);
	};
	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const exportPDFWithComponent = async () => {
		try {
			const printArea = document.createElement("div");

			printArea.innerHTML = `
  

	  <div class="col-lg-12">
                                    <div class="invoice p-3 mb-3">
                                        <div class="row invoice-info">
                                            <div class="col-sm-4 invoice-col">
                                                <div class="company-logo"><img src="/1689772513.jpeg" alt="logo" style="height:200;width:250" class="lg-logo">
                                                  
                                                </div>
                                            </div>
                                        	<h3 style="text-align:center">Purchase Payment Reports </h3>
                                        </div>
                                        <hr>
                                     

                                    </div>
                                </div>
    `;

			// Append the cloned table to the printArea
			const originalTable = document.getElementById("p_payment-table");
			const clonedTable = originalTable.cloneNode(true);
			clonedTable.getElementsByTagName("thead")[0].style.backgroundColor =
				"blue";
			clonedTable.getElementsByTagName("thead")[0].style.color = "white";
			// Set visibility of cloned table columns to true
			const clonedTableColumns = clonedTable.getElementsByTagName("th");
			for (let i = 0; i < clonedTableColumns.length; i++) {
				clonedTableColumns[i].style.display = "table-cell";
			}

			// Calculate the total sum of the last column (column 7)
			let totalSum = 0;
			const rows = clonedTable.getElementsByTagName("tr");
			for (let i = 1; i < rows.length; i++) {
				// Start from 1 to skip the header row
				const cells = rows[i].getElementsByTagName("td");
				if (cells.length >= 8) {
					const cellValue = parseFloat(cells[7].textContent); // Assuming column 7 is 0-based
					if (!isNaN(cellValue)) {
						totalSum += cellValue;
					}
				}
			}

			// Create a new row for the total sum
			const totalRow = document.createElement("tr");
			totalRow.style.backgroundColor = "gray";
			totalRow.style.color = "white";
			for (let i = 0; i < 8; i++) {
				// Assuming there are 7 columns in your table
				const totalCell = document.createElement("td");
				if (i === 7) {
					// If it's the last column (column 7), add the total sum
					totalCell.textContent = `Total Paid: ${totalSum.toFixed(2)}`; // Assuming you want 2 decimal places
				} else {
					totalCell.textContent = ""; // Empty for other columns
				}
				totalRow.appendChild(totalCell);
			}

			// Add the total row to the table body
			clonedTable.querySelector("tbody").appendChild(totalRow);

			// Append the cloned table to the printArea
			printArea.appendChild(clonedTable);

			printArea.appendChild(clonedTable);

			const opt = {
				margin: 1,
				filename: "purchase payment.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: "in", format: [20.4, 25], orientation: "landscape" },
			};

			html2pdf().set(opt).from(printArea).save();
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};
	const exportExcelWithComponent = async () => {
		const table = document.getElementById("p_payment-table");
		const rows = Array.from(table.getElementsByTagName("tr"));

		// Extract table headers
		const headers = Array.from(rows.shift().getElementsByTagName("th")).map(
			(header) => header.textContent
		);

		// Extract table data
		const data = rows.map((row) => {
			const cells = Array.from(row.getElementsByTagName("td")).map(
				(cell) => cell.textContent
			);
			return cells.join(",");
		});
		let totalSum = 0;
		rows.forEach((row) => {
			const cells = Array.from(row.getElementsByTagName("td"));
			if (cells.length >= 8) {
				const cellValue = parseFloat(cells[7].textContent); // Assuming column 7 is 0-based
				if (!isNaN(cellValue)) {
					totalSum += cellValue;
				}
			}
		});
		const totalRow = [
			"Total",
			"",
			"",
			"",
			"",
			"",
			"",
			`${totalSum.toFixed(2)}`,
		]; // Add the total sum as the 8th column
		// Create report header
		const reportHeader =
			"Report Name:Purchase Payment Report\nDate From : " +
			filterList.datefrom +
			"\nDate To : " +
			filterList.dateto +
			"\n\n";

		// Create CSV content with report header
		const csvContent =
			reportHeader +
			`${headers.join(",")}\n${data.join("\n")}\n${totalRow.join(",")}`; // Assuming 2 decimal places for the total sum
		// Create a download link
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = "purchase_payment_reports.csv";

		// Trigger the download
		downloadLink.click();

		// Cleanup
		URL.revokeObjectURL(url);
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Purchase Payment</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active">
											{" "}
											Purchase Payment
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="department_id">Client *</label>
													<Controller
														name="cus_id"
														control={control}
														rules={{ required: true }}
														render={({ field }) => (
															<AsyncSelect
																{...field}
																isClearable
																defaultOptions={supplierList}
																loadOptions={(e) =>
																	dispatch(getSupplierList(e))
																}
																getOptionLabel={(option) => option.sp_name}
																getOptionValue={(option) => option.id}
																placeholder="Select Supplier"
																value={supplierList.find(
																	(obj) => obj.id === field.value
																)}
																onChange={(e) => {
																	field.onChange(e);
																	setFilterList({
																		...filterList,
																		sp_id: e.id,
																	});
																}}
															/>
														)}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>

											<div
												className="col-md-2 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary"
													onClick={getPaymentList}
												>
													Search
												</button>
											</div>

											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success ml-2"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-info ml-2"
													id="export_to_pdf"
													onClick={exportExcelWithComponent}
												>
													Excel
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="p_payment-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Supplier</th>
														<th> Date</th>

														<th>Invoice No</th>
														<th>Cheque No</th>
														<th>Account</th>
														<th>Bill Amount</th>
														<th>Paid Amount</th>
														<th>Notes</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
