import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { accountsSelector } from "../../redux/Accounts";
import { getAccount } from "../../redux/api/apiAccounts";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { Controller, useForm } from "react-hook-form";
import DataTable from "datatables.net-dt";
import { getCashFlowReport } from "../../redux/api/apiReports";
import { cashflowSelector } from "../../redux/Reports";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
export default function Cash_Flow() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const accounts = useSelector(accountsSelector);
	const flowData = useSelector(cashflowSelector);
	useEffect(() => {
		dispatch(getAccount());
		dispatch(getCashFlowReport());
	}, []);
	const [currentDate, setCurrentDate] = useState(new Date());
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate() + 3);
	// console.log(defaultDate);

	const [filterList, setFilterList] = useState({
		datefrom: "",
		dateto: "",
		account_id: "",
	});
	let table = new DataTable("#flow-table");
	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#flow-table", {
			data: flowData,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.transaction_date) {
							return data.transaction_date;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account) {
							return data.account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.notes) {
							return data.notes;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.type === "debit") {
							return data.type;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.type === "credit") {
							return data.type;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.balance) {
							return data.balance;
						}
						return "";
					},
				},
			],
		});
	}, [flowData, table]);
	const [exportToPdf, setExportToPdf] = useState(false);
	const getFlowData = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();
		dispatch(
			getCashFlowReport(filterList, (res) => {
				if (res) {
					Swal.close();
					setExportToPdf(true);
				}
			})
		);
	};
	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const exportPDFWithComponent = async () => {
		try {
			const printArea = document.createElement("div");
			// // Fetch the image dynamically
			// const response = await fetch(logo); // Replace with the correct URL for your dynamically generated image
			// const blob = await response.blob();
			// const imageURL = URL.createObjectURL(blob);

			// // Create a temporary image element
			// const img = new Image();
			// img.src = imageURL;
			// Add your HTML code above the table
			printArea.innerHTML = `
  

	  <div class="col-lg-12">
                                    <div class="invoice p-3 mb-3">
                                        <div class="row invoice-info">
                                            <div class="col-sm-4 invoice-col">
                                                <div class="company-logo"><img src="/1689772513.jpeg" alt="logo" style="height:200;width:250" class="lg-logo">
                                                    <address class="mt-2"> <strong>Phone:
                                                        </strong>
                                                         ${company_phone}<br> <strong>Email: </strong>
                                                        ${company_email}<br> <strong>Address: </strong>
                                                        ${company_address}
                                                    </address>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <hr>
                                     

                                    </div>
                                </div>
    `;

			// Append the cloned table to the printArea
			const originalTable = document.getElementById("flow-table");
			const clonedTable = originalTable.cloneNode(true);
			clonedTable.getElementsByTagName("thead")[0].style.backgroundColor =
				"blue";
			clonedTable.getElementsByTagName("thead")[0].style.color = "white";
			// Set visibility of cloned table columns to true
			const clonedTableColumns = clonedTable.getElementsByTagName("th");
			for (let i = 0; i < clonedTableColumns.length; i++) {
				clonedTableColumns[i].style.display = "table-cell";
			}

			printArea.appendChild(clonedTable);

			const opt = {
				margin: 1,
				filename: "CashFlow.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: "in", format: [20.4, 20], orientation: "landscape" },
			};

			html2pdf().set(opt).from(printArea).save();
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const exportExcelWithComponent = async () => {
		const table = document.getElementById("flow-table");
		const rows = Array.from(table.getElementsByTagName("tr"));

		// Extract table headers
		const headers = Array.from(rows.shift().getElementsByTagName("th")).map(
			(header) => header.textContent
		);

		// Extract table data
		const data = rows.map((row) => {
			const cells = Array.from(row.getElementsByTagName("td")).map(
				(cell) => cell.textContent
			);
			return cells.join(",");
		});

		// Create report header
		const reportHeader =
			"Report Name:Cash Flow Report\nDate From : " +
			filterList.datefrom +
			"\nDate To : " +
			filterList.dateto +
			"\n\n";

		// Create CSV content with report header
		const csvContent =
			reportHeader + `${headers.join(",")}\n${data.join("\n")}`;

		// Create a download link
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = "cash_flow_reports.csv";

		// Trigger the download
		downloadLink.click();

		// Cleanup
		URL.revokeObjectURL(url);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Cash Flow</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active"> Cash Flow</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-4">
												<label>From Accounts </label>
												<Controller
													name="from_account_id"
													control={control}
													rules={{ required: false }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={accounts}
															loadOptions={(inputValue) =>
																new Promise((resolve) => {
																	setTimeout(() => {
																		resolve(
																			accounts.filter((i) =>
																				i.account_name
																					.toLowerCase()
																					.includes(inputValue.toLowerCase())
																			)
																		);
																	}, 1000);
																})
															}
															getOptionLabel={(option) => option.account_name}
															getOptionValue={(option) => option.id}
															placeholder="Select Accounts"
															value={accounts.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																setFilterList({
																	...filterList,
																	account_id: e.id,
																});
															}}
														/>
													)}
												/>
											</div>

											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary"
													onClick={getFlowData}
												>
													Search
												</button>
											</div>

											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success ml-2"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-info ml-2"
													id="export_to_pdf"
													onClick={exportExcelWithComponent}
												>
													Excel
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="flow-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>DATE</th>
														<th>ACCOUNT</th>
														<th>AMOUNT</th>
														<th>DESCRIPTION</th>
														<th>DEBIT </th>
														<th>CREDIT </th>
														<th>BALANCE</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
